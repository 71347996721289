import  {AxiosError} from 'axios'

export interface DataProps{
    [key: string]:string|boolean|number|null|React.ReactElement|((text: string) => React.ReactNode)| undefined;
    
 
}

export interface CustomAxiosError extends Omit<AxiosError,'response'>{
    response? :{
        data:{
            error:string
        } 
    }
}

export interface AuthTokenType{
    headers:{
        Authorization:string}
}

export interface UserType{
    email:string
    fullname:string
    id:number
    created_at:string
    role:string
    last_login:string
    
}

export interface AuthProps{
    errorCallBack?:()=>void,
    successCallBack?:()=>void,
}


export interface StoreProps{
    user:UserType | null,
    updatePasswordUserId:number|null
}

export enum ActionTypes{
    UPDATE_USER_INFO ="[action] update user info",
    UPDATE_PASSWORD_USER_ID ="[action] update password id"
}

export type ActionProps={
    type: ActionTypes.UPDATE_USER_INFO,
    payload:UserType | null
}| {
    type: ActionTypes.UPDATE_PASSWORD_USER_ID,
    payload:number | null
}

export interface StoreProviderProps{
    state:StoreProps,
    dispatch:(arg:ActionProps)=>void
}

export interface headerprops {
  id: number;
  name: string;
}

export interface subheaderprops {
  id: number;
  header: headerprops;
  name: string;
}

export interface AddTrasactionFormModalProps{
  isVisible?:boolean
  onSuccessCallBack:()=>void
  onClose: () =>void
  editingTransaction?: Transactionprops | null;
  onCloseWithoutEditing:()=>void

}

export interface Transactionprops {
  franchisee: FranchiseeProps; // Allow parent to reference another category or be null for top-level categories
  id: number;
  subheader: subheaderprops;
  date:Date|moment.Moment|string|any;  // You can adjust the date format as needed
  amount: number;
}

// TypeScript interface for ProfitLossStatement
export interface ProfitLossStatementprops {
  franchisee: FranchiseeProps; // Allow parent to reference another category or be null for top-level categories
  month: number;
  year: number;
  total_income: number;
  total_expense: number;
  overall_profit: number;
}

// TypeScript interface for HeaderProfitLoss
export interface HeaderProfitLossprops {
  franchisee: FranchiseeProps; // Allow parent to reference another category or be null for top-level categories
  month: number;
  year: number;
  header: headerprops; // ForeignKey to Header, represented by ID
  subheader: subheaderprops; // ForeignKey to Subheader, represented by ID
  amount: number;
}

export interface AddFranchiseeFormModalProps{
  isVisible?:boolean
  onSuccessCallBack:()=>void
  onClose: () =>void
  editingFranchisee?: FranchiseeProps | null;
  onCloseWithoutEditing:()=>void

}


export interface FranchiseeProps{
  id:number
  name:string
  phone:string
  state:string
}




export interface UserProps{
    created_at:string
    email:string
    fullname:string
    is_active:string
    last_login:string
    role:string
    key?:number
    color?:string
    id:number
    franchisee: FranchiseeProps; // Allow parent to reference another category or be null for top-level categories
  
  }
  

 
  export interface AddGroupFormModalProps{
    isVisible?:boolean
    onSuccessCallBack:()=>void
    onClose: () =>void
}



export interface AddOnHandInventoryFormProps{
  isVisible?:boolean
  onSuccessCallBack:()=>void
  onClose: () =>void
}


export interface OnhandInventoryProps{
  key:string;
  id:number
  totalunits:number
  status:string
  sku_code:{
    code:string
    name:string
    
    category:{
      name:string
      parent:{
        name:string
      }
    }
    }
  storage_location:{
      name:string
      }
      created_at:string
  }




export interface AddUserFormModalProps{
    isVisible?:boolean
    onSuccessCallBack:()=>void
    onClose: () =>void
    editingUser?: UserProps | null;
    onCloseWithoutEditing:()=>void

}
 

export interface EmailTracking {
  id: number;
  estimate: estimatesProps; // assuming estimate ID is a number
  firstmaildate: string | null; // ISO date string or null
  firstmailflag: boolean;
  firstmailstatus: string;
  secondmaildate: string | null;
  secondmailflag: boolean;
  secondmailstatus: string;
  thirdmaildate: string | null;
  thirdmailflag: boolean;
  thirdmailstatus: string;
}

export interface AddEmailTrackingFormProps{
  isVisible?:boolean
  onSuccessCallBack:()=>void
  onClose: () =>void
  editingEmailTracking?: EmailTracking | null;
  onCloseWithoutEditing:()=>void

}



export interface category {
  id: number;
  name: string;
  parent: category | null; // Allow parent to reference another category or be null for top-level categories
  is_active: boolean;
}

export interface AddCategoryFormProps{
    isVisible?:boolean
    onSuccessCallBack:()=>void
    onClose: () =>void
    editingCategory?: category | null;
    onCloseWithoutEditing:()=>void

}

export interface AddUnitofmeasureFormProps{
    isVisible?:boolean
    onSuccessCallBack:()=>void
    onClose: () =>void
    editingUnitofmeasure?: unitofmeasure | null;
    onCloseWithoutEditing:()=>void

}


  // Unit of Measure model
export interface unitofmeasure {
    id: number;
    name: string;
    is_active: boolean;
    
  }

export interface AddCurrencyFormProps{
    isVisible?:boolean
    onSuccessCallBack:()=>void
    onClose: () =>void
    editingCurrency?: currency | null;
    onCloseWithoutEditing:()=>void
}


  // Currency model
export interface currency {
    id: number;
    name: string;
    is_active: boolean;
  }
  
export interface AddLocationFormProps{
    isVisible?:boolean
    onSuccessCallBack:()=>void
    onClose: () =>void
    editingLocation?: location | null;
    onCloseWithoutEditing:()=>void
}


  // Location model
export interface location {
  franchisee: FranchiseeProps; // Allow parent to reference another category or be null for top-level categories
  id: number;
    name: string;
    type: string;
    address: string;
    city: string;
    state: string;
    country: string;
  }

export interface AddCustomerFormProps{
    isVisible?:boolean
    onSuccessCallBack:()=>void
    onClose: () =>void
    editingCustomer?: customer | null;
    onCloseWithoutEditing:()=>void
}



export interface AddPricebylocationFormProps{
  isVisible?:boolean
  onSuccessCallBack:()=>void
  onClose: () =>void
  editingPricebyLocation?: Pricebylocation | null;
  onCloseWithoutEditing:()=>void
}

export interface Pricebylocation
{
          key:string;
          id:number
          product_details:{
            name:string
            id:number
            }
          franchisee:FranchiseeProps
          sku_price:number
          sku_name:string
          franchisee_name:string
          }






  // Customer model
export interface customer {
    id: number;
    name: string;
    email: string;
    address: string;
    city: string;
    state: string;
    zip: string;
    phone: string;
    type: string;
    customer_location?:{
      id:number
      name:string
      }

  }


  export interface AddLeadFormProps{
    isVisible?:boolean
    onSuccessCallBack:()=>void
    onClose: () =>void
    editingLead?: lead | null;
    onCloseWithoutEditing?:()=>void
}


  export interface lead {
    franchisee: FranchiseeProps; // Allow parent to reference another category or be null for top-level categories
    id: number;
    name: string;
    email: string;
    address: string;
    city: string;
    state: string;
    zip: string;
    phone: string;
    source: string;
  }


  export interface AddAppointmentFormProps{
    isVisible?:boolean
    onSuccessCallBack:()=>void
    onClose: () =>void
    editingAppointment?: Appointment | null;
    onCloseWithoutEditing?:()=>void
    initialDateTime?: Date | null; // New prop to pass the selected date and time
  
}


  export interface Appointment {
    franchisee: FranchiseeProps; // Allow parent to reference another category or be null for top-level categories
    id: number;
    from_location:location|null
    employee:UserProps|null
    lead:lead|null
    date:Date|moment.Moment|string|any 
  }




export interface AddSupplierFormProps{
    isVisible?:boolean
    onSuccessCallBack:()=>void
    onClose: () =>void
    editingSupplier?: supplier | null;
    onCloseWithoutEditing:()=>void
}

  // Supplier model
export interface supplier {
  franchisee: FranchiseeProps; // Allow parent to reference another category or be null for top-level categories
  id: number;
    name: string;
    email: string;
    address: string;
    city: string;
    state: string;
    country: string;
    phone: string;
    type: string;
    supplier_location?:{
      id:number
      name:string
      }
  }

  
export interface AddProductFormProps{
    isVisible?:boolean
    onSuccessCallBack:()=>void
    onClose: () =>void
    editingProduct?: product | null;
    onCloseWithoutEditing:()=>void
}


  // Product model
export interface product {
  field?:string
    id: number;
    quantity?:number;
    image:string;
    imageinfo:string;
    name: string;
    code: string;
    sku_weight: number;
    sku_weight_uom: unitofmeasure;
    color: string;
    sku_length: number;
    sku_length_uom: unitofmeasure;
    sku_height: number;
    sku_height_uom: unitofmeasure;
    sku_description: string;
    sku_uom: unitofmeasure;
    sku_buy_cost: number;
    sku_cost_new: number;
    sku_price: number;
    category: category;
    currency: currency;
    weight_uom:string,
    length_uom:string,
    height_uom:string,
    productdropdown:string
    uom:string,
    curr:string
    cat:string
    subcat:string
  }
  
  export interface AddRemovePurchaseItemProps{
    [key:number]:number
  }

  export interface purchaseProps
{
          key:string;
          id:number
          item:string
          po_number?:string
          departure_date?:Date|moment.Moment|string 
          free_days?:string
          internal_reference? :string
          origin_country? :string
          origin_port? :string
          destination_port? :string
          container_id? :string
          portfolio? :string
          project? :string
          shipping_company? :string
          din_number? :string
          expected_delivery_date?:Date|moment.Moment|string
          actual_delivery_date?:Date|moment.Moment|string
          quantity:number
          status?:string
          amount?:number
          pending?:number
          paid?:number
          cost:number
          change_delivery_date?:Date
          action?:React.ReactElement
          sales_order_reference?:salesProps
          from_location?:{
            name:string
            }
            to_location?:{
              name:string
              }
          purchaseorder_items?:{
          item:{
                  id:string
                  name:string
                  quantity:number
                  cost:number
                }
                  }[],
          
          }

export interface AddRemovesalesItemProps{
            [key:number]:number
          }
        
          export interface salesProps
        {         customer_name?:string
          employee_name?:string
                  key:string;
                  id:number
                  item:string
                  amount?:number
                  employee?:UserProps|null
                  expected_delivery_date?:Date|moment.Moment|string|any
                  actual_delivery_date?:Date|moment.Moment|string|any
                  quantity:number
                  status?:string
                  mode?:string
                  change_delivery_date?:Date
                  action?:React.ReactElement
                  price?:number
                  from_location?:{
                    name:string
                    id:any
                    }
                    customer?:{
                      name:string
                      id:any
                      }
                  salesorder_items?:{
                  item:{
                          id:any
                          name:any
                          quantity:any
                          price:any
                          amount?:any
                          sku_price?:any
                        }
                        amount?:any
                        quantity:number
                          }[],
                  
                  }


                  export interface AddRemovestocktransferItemProps{
                    [key:number]:number
                  }
                
                  export interface stocktransferProps
                {
                          key:string;
                          id:number;
                          name:string
                          expected_delivery_date?:Date|moment.Moment|string
                          actual_delivery_date?:Date|moment.Moment|string
                          departure_date? :Date|moment.Moment|string
                          project? :string
                          portfolio? :string
                          internal_reference? :string
                          transport_company? :string
                          truck_plate? :string
                          guide_number? :string
                          quantity:number
                          status?:string
                          change_delivery_date?:Date
                          action?:React.ReactElement
                          from_location?:{
                            name:string
                            }
                            to_location?:{
                              name:string
                              }
                          stocktransferorder_items?:{
                          item:{
                                  id:string
                                  name:string
                                  quantity:number
                                }
                                  }[],
                          
                          }
        

                          export interface InventoryProps{
                            id: number;
                            name: string;
                            code: string;
                            sku_weight: number;
                            sku_weight_uom: unitofmeasure;
                            sku_length: number;
                            sku_length_uom: unitofmeasure;
                            sku_height: number;
                            sku_height_uom: unitofmeasure;
                            sku_description: string;
                            sku_uom: unitofmeasure;
                            sku_buy_cost: number;
                            sku_price: number;
                            category: category;
                            currency: currency;
                            total_units:number;
                          }
                          


                          export interface AddRemoveestimatesItemProps{
                            [key:number]:number
                          }
                        
                          export interface estimatesProps {
                            franchisee: FranchiseeProps; // Allow parent to reference another category or be null for top-level categories
                            lead_name?:string
                            mode?:string
                            key?: string;
                            id: number;
                            item: string;
                            quantity: number;
                            status?: string;
                            action?: React.ReactElement;
                            from_location?: {
                              name: string;
                              id:string;
                            };
                            lead?: lead
                            estimate_items?: {
                              quantity?: number;
                              item: {
                                id: any;
                                name: string;
                                quantity: number;
                              };
                            }[];
                            specialinstructions?: string; // Add missing properties
                            signature?: string;
                            estimate_image?: string;
                            date?: string;
                            completiondate?: string;
                            preferredinstallday?: string;
                            preferredstarttime?: string;
                            source?: string;
                            totalprice?: string;
                            deposit?: string;
                            balance?: string;
                            securitycvv?: string;
                            cash?: string;
                            cc?: string;
                            cheque?: string;
                            creditcard?: string;
                            creditcardexpiry?: string;
                            created_at?: string;
                          }
                
                                  export interface linegraphprops{
                                    status?:string
                                    value:number
                                    key?:number
                                    id:number
                                    month?:string
                                  
                                  }



                                  export interface CalendarPopupProps{
                                    onSuccessCallBack:()=>void
                                    Open?:boolean
                                    pid:string
                                    handleCancel:()=>void
                                    currentcomment: string
                                    
                                  }
                                  


                                  export interface DashboardData {
                                    franchisee_name: string;
                                    total_products: number;
                                    total_suppliers: number;
                                    total_customers: number;
                                    sales_mtd: number;
                                    sales_ytd: number;
                                    costs_ytd: number;
                                    costs_mtd: number;
                                    profit_ytd: number;
                                    profit_mtd: number;
                                    inventory_dollars: number;
                                    inventory_units: number;
                                    open_estimates: number;
                                    open_purchases: number;
                                    pending_installations: number;
                                    total_leads: number;
                                    profit_loss_data: any[]; // Define appropriate type
                                    estimates_data: any[]; // Define appropriate type
                                    sales_data: any[]; // Define appropriate type
                                }



                                export interface EmailProps{
                                  emailtext: { __html: string };
                                  type:string,
                                  id:string
                                  created_at:string
                              
                              }
                              
                              export interface AddEmailProps{
                                  isVisible?:boolean
                                  onSuccessCallBack:()=>void
                                  onClose: () =>void
                              }
                              
                              export interface EditEmailProps{
                                  isVisible?:boolean
                                  onSuccessCallBack:()=>void
                                  onClose: () =>void
                                  currententry:EmailProps
                              }


                              export interface Sectionprop {
                                id: number;
                                title: string;
                                description?: string | null;
                                created_at: string; // ISO 8601 timestamp
                                updated_at: string; // ISO 8601 timestamp
                                lessons: Lessonprop[];  // Change to an array of Lessonprop
                              }
                              
                              // Lesson Type
                              export interface Lessonprop {
                                id: number;
                                title: string;
                                order: number;
                                created_at: string; // ISO 8601 timestamp
                                updated_at: string; // ISO 8601 timestamp
                                contents: LessonContentprop[]; // Lesson content
                              }
                              
                              // LessonContent Type
                              export interface LessonContentprop {
                                id: number;
                                video?: string | null; // File path or URL of the video
                                write_up?: string | null; // Text content
                                created_at: string; // ISO 8601 timestamp
                                updated_at: string; // ISO 8601 timestamp
                              }