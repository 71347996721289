import React, { FC, useEffect, useState } from "react";
import { Modal, Spin, Button, DatePicker, message } from "antd";
import dayjs, { Dayjs } from "dayjs";
import axios, { AxiosResponse } from "axios";
import { lead, customer, salesProps, Appointment } from "../utils/types";
import { getLeadestimates, getCustomersales, getAuthToken } from "../utils/functions";
import { appointmentsupdateurls, salesordereditdateViewUrl, salesorderupdatesUrl } from "../utils/network";
import AddCustomerForm from "./AddCustomerForm";
import AddLeadForm from "./AddLeadForm";

interface AppointmentDetailsPopupProps {
  isOpen: boolean;
  appointmentType: "sales" | "visit";
  appointmentId: number;
  onClose: () => void;
  onSaveSuccess: () => void;
  appointment: salesProps | Appointment | undefined;
}

const AppointmentDetailsPopup: FC<AppointmentDetailsPopupProps> = ({
  isOpen,
  appointmentType,
  appointmentId,
  onClose,
  onSaveSuccess,
  appointment,
}) => {
  const [data, setData] = useState<lead | customer | null>(null);
  const [fetching, setFetching] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [newDate, setNewDate] = useState<Dayjs | null>(null);

  const [drawerVisible, setDrawerVisible] = useState<boolean>(false); // State for drawer visibility
  const [editingCustomer, setEditingCustomer] = useState<customer | null>(null);
  const [editingLead, setEditingLead] = useState<lead | null>(null);


  const handleEditlcClick = () => {
    if (appointmentType === "sales" && data) {
      setEditingCustomer(data as customer);
    } else if (appointmentType === "visit" && data) {
      setEditingLead(data as lead);
    }
    setDrawerVisible(true);
  };

  const onCloseWithoutEditing = () => {
    setDrawerVisible(false);
    setEditingCustomer(null);
    setEditingLead(null);
  };
  useEffect(() => {
    const fetchData = async () => {
      setFetching(true);
      try {
        if (appointmentType === "visit" && appointment) {
          await getLeadestimates((leads: lead[]) => {
            const lead = leads.find((lead) => lead.id === appointmentId);
            setData(lead || null);
            setFetching(false);
          }, setFetching);
        } else if (appointmentType === "sales" && appointment && 'salesorder_items' in appointment) {
          await getCustomersales((customers: customer[]) => {
            const customer = customers.find((customer) => customer.id === appointmentId);
            setData(customer || null);
            setFetching(false);
          }, setFetching);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setFetching(false);
      }
    };

    if (isOpen) {
      fetchData();
    } else {
      setData(null);
    }
  }, [isOpen, appointmentType, appointmentId]);

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleDateChange = (date: any, dateString: any) => {
    setNewDate(date);
  };

  const handleSaveClick = async () => {
    if (!newDate) {
      message.error("Please select a new date.");
      return;
    }

    try {
      const headers = getAuthToken() as { headers: { Authorization: string } };
      const url = appointmentType === "visit" ? appointmentsupdateurls : salesordereditdateViewUrl;
      const values = appointmentType === "visit"
        ? { date: newDate.toISOString() }
        : { expected_delivery_date: newDate.toISOString() };

      const response: AxiosResponse = await axios.patch(`${url}/${appointment?.id}`, values, headers);

      if (response.status === 200) {
        message.success("Appointment date updated successfully.");
        onSaveSuccess(); // Call the callback function to update the calendar
        onClose(); // Close the modal after saving
      } else {
        message.error("Failed to update appointment date.");
      }
    } catch (error) {
      message.error("Failed to update appointment date.");
    }
  };

  return (
    <Modal
      title="Appointment Details"
      visible={isOpen}
      onCancel={onClose}
      footer={null}
      style={{ borderRadius: '10px', maxWidth: '80%' }}
      bodyStyle={{ padding: '20px' }}
    >
      {fetching ? (
        <div style={{ textAlign: 'center' }}>
          <Spin size="large" />
          <p style={{ marginTop: '10px' }}>Loading...</p>
        </div>
      ) : (
        data ? (
          <div>
            <div style={{ marginBottom: '20px' }}>
              <p style={{ fontSize: '20px', fontWeight: 'bold', marginBottom: '5px' }}>{appointment?.id}</p>

              <p
    style={{ fontSize: '20px', fontWeight: 'bold', marginBottom: '5px', cursor: 'pointer', color: '#1890ff' }}
    onClick={handleEditlcClick} // Trigger the edit details function on click
  >
    {data.name}
  </p>
              <p style={{ fontSize: '16px', color: '#666', marginBottom: '5px' }}>{data.email}</p>
              <p style={{ fontSize: '16px', color: '#666', marginBottom: '5px' }}>{data.phone}</p>
              <p style={{ fontSize: '16px', color: '#666', marginBottom: '5px' }}>{data.address}, {data.city}, {data.state}, {data.zip}</p>
              <p style={{ fontSize: '16px', color: '#666', marginBottom: '5px' }}>Employee : {appointment?.employee?.fullname}</p>

            </div>
            {appointmentType === "sales" && appointment && 'salesorder_items' in appointment && (
              <div>
                <p style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Sales Order Items:</p>
                <ul>
                  {appointment.salesorder_items?.map((salesItem, index) => (
                     <li key={index}>
                     {salesItem.item.name} : {salesItem.quantity}
                   </li>                    
                  ))}
                </ul>
              </div>
            )}
            {editMode ? (
              <div>
                <DatePicker
                showTime
                format="YYYY-MM-DD HH:mm"
                value={newDate} onChange={handleDateChange} />
                <Button className="bbutton" onClick={handleSaveClick} style={{ marginTop: '10px' }}>
                  Save
                </Button>
              </div>
            ) : (
              <Button className="bbutton" onClick={handleEditClick} style={{ marginTop: '10px' }}>
                Edit Appointment
              </Button>
            )}
          </div>
        ) : (
          <p style={{ textAlign: 'center', color: '#ff4d4f', fontSize: '18px' }}>
            No data found for the specified appointment.
          </p>
        )
      )}
              {appointmentType === "sales" ? (
        <AddCustomerForm
          onSuccessCallBack={() => {
            setDrawerVisible(false);
            onSaveSuccess(); // Call the callback function to update the calendar
            onClose(); // Close the modal after saving

            // Assuming you have getCustomer method available in context to refresh customers
          }}
          isVisible={drawerVisible}
          onClose={() => setDrawerVisible(false)}
          editingCustomer={editingCustomer}
          onCloseWithoutEditing={onCloseWithoutEditing}
        />
      ) : (
        <AddLeadForm
          onSuccessCallBack={() => {
            setDrawerVisible(false);
            onSaveSuccess(); // Call the callback function to update the calendar
            onClose(); // Close the modal after saving

            // Assuming you have getLead method available in context to refresh leads
          }}
          isVisible={drawerVisible}
          onClose={() => setDrawerVisible(false)}
          editingLead={editingLead}
          onCloseWithoutEditing={onCloseWithoutEditing}
        />
      )}
    </Modal>
  );
};

export default AppointmentDetailsPopup;