import { useContext,useEffect } from "react"
import { ActionTypes,Appointment,AuthProps,DashboardData,EmailProps,EmailTracking,FranchiseeProps,HeaderProfitLossprops,LessonContentprop,Lessonprop,ProfitLossStatementprops,Sectionprop,Transactionprops,UserType, estimatesProps, headerprops, purchaseProps, salesProps, stocktransferProps, subheaderprops } from "./types"
import { authHandler,getAppointments, getCategory, getCurrency, getCustomer, getCustomersales, getEmail, getEmailTracking, getFranchisee, getLead, getLeadestimates, getLesson, getLessonContent, getLocation, getOnhandInventory, getPricebyLocation, getProduct,getSankey,getSection,getSupplier, getUnitofmeasure, getUsers, getestimates, getheaderprofitloss, getheaders, getprofitloss, getpurchase, getsales, getsalescalendar, getstocktransfer, getsubheaders, getsummarys, gettransactions} from "./functions"
import { store } from "./store"




export const useAuth =async({errorCallBack,successCallBack}:AuthProps)=>{
    const{dispatch}=useContext(store)
    
    useEffect(()=>{
        const checkUser=async () => {
            const user: UserType|null=await authHandler()
            if(!user){
                if(errorCallBack){
                    errorCallBack()
                }
                return
            }
            if(successCallBack){
                dispatch({type:ActionTypes.UPDATE_USER_INFO,payload:user})
                successCallBack()
            }
            
        }
        checkUser()
    },[])
}


export const useGetUsers = (setUsers: (data: any) => void, setFetching: (val: boolean) => void) => {
  useEffect(() => {
    setFetching(true);
    getUsers(setUsers, setFetching);
  }, [setUsers, setFetching]);
};


export const useGetCategory = (setCategory: (data: any) => void, setFetching: (val: boolean) => void) => {
    useEffect(() => {
      setFetching(true);
      getCategory(setCategory, setFetching);
    }, [setCategory, setFetching]);
  };
  
  export const useGetUnitofmeasure = (
    setUnitofmeasure: (data: any) => void,
    setFetching: (val: boolean) => void
  ) => {
    useEffect(() => {
      setFetching(true);
      getUnitofmeasure(setUnitofmeasure, setFetching);
    }, [setUnitofmeasure, setFetching]);
  };
  
  export const useGetCurrency = (setCurrency: (data: any) => void, setFetching: (val: boolean) => void) => {
    useEffect(() => {
      setFetching(true);
      getCurrency(setCurrency, setFetching);
    }, [setCurrency, setFetching]);
  };
  
  export const useGetLocation = (setLocation: (data: any) => void, setFetching: (val: boolean) => void) => {
    useEffect(() => {
      setFetching(true);
      getLocation(setLocation, setFetching);
    }, [setLocation, setFetching]);
  };
  
  export const useGetCustomer = (setCustomer: (data: any) => void, setFetching: (val: boolean) => void,franchisee_id:any) => {
    useEffect(() => {
      setFetching(true);
      getCustomer(setCustomer, setFetching,franchisee_id);
    }, [setCustomer, setFetching]);
  };


  export const useGetCustomersales = (setCustomer: (data: any) => void, setFetching: (val: boolean) => void) => {
    useEffect(() => {
      setFetching(true);
      getCustomersales(setCustomer, setFetching);
    }, [setCustomer, setFetching]);
  };

  export const useGetOnhandIventory = (setOnhandInventory: (data: any) => void, setFetching: (val: boolean) => void,franchisee_id:any) => {
    useEffect(() => {
      setFetching(true);
      getOnhandInventory(setOnhandInventory, setFetching,franchisee_id);
    }, [setOnhandInventory, setFetching]);
  };

  
  export const useGetPricebyLocation = (setPricebyLocation: (data: any) => void, setFetching: (val: boolean) => void) => {
    useEffect(() => {
      setFetching(true);
      getPricebyLocation(setPricebyLocation, setFetching);
    }, [setPricebyLocation, setFetching]);
  };



  export const useGetSupplier = (setSupplier: (data: any) => void, setFetching: (val: boolean) => void) => {
    useEffect(() => {
      setFetching(true);
      getSupplier(setSupplier, setFetching);
    }, [setSupplier, setFetching]);
  };
  
  export const useGetProduct = (setProduct: (data: any) => void, setFetching: (val: boolean) => void) => {
    useEffect(() => {
      setFetching(true);
      getProduct(setProduct, setFetching);
    }, [setProduct, setFetching]);
  };
  
  export const useGetpurchase=(setpurchase:(data:purchaseProps[])=>void,setFetching:(val:boolean)=>void,franchisee_id:any)=>{
    useEffect(()=>{
      getpurchase(setpurchase,setFetching,franchisee_id)
    },[])
  
  }
  
  export const useGetsales=(setsales:(data:salesProps[])=>void,setFetching:(val:boolean)=>void,franchisee_id:any)=>{
    useEffect(()=>{
      getsales(setsales,setFetching,franchisee_id)
    },[])
  
  }

  export const useGetstocktransfer=(setstocktransfer:(data:stocktransferProps[])=>void,setFetching:(val:boolean)=>void,franchisee_id:any)=>{
    useEffect(()=>{
      getstocktransfer(setstocktransfer,setFetching,franchisee_id)
    },[])
  
  }


  export const useGetLead = (setLead: (data: any) => void, setFetching: (val: boolean) => void,franchisee_id:any) => {
    useEffect(() => {
      setFetching(true);
      getLead(setLead, setFetching,franchisee_id);
    }, [setLead, setFetching]);
  };


  export const useGetLeadestimates = (setLead: (data: any) => void, setFetching: (val: boolean) => void) => {
    useEffect(() => {
      setFetching(true);
      getLeadestimates(setLead, setFetching);
    }, [setLead, setFetching]);
  };



  export const useGetEstimates=(setestimates:(data:estimatesProps[])=>void,setFetching:(val:boolean)=>void,franchisee_id:any)=>{
    useEffect(()=>{
      getestimates(setestimates,setFetching,franchisee_id)
    },[])
  
  }

  export const useGetsalescalendar=(setsales:(data:salesProps[])=>void,setFetching:(val:boolean)=>void,franchisee_id:any)=>{
    useEffect(()=>{
      getsalescalendar(setsales,setFetching,franchisee_id)
    },[])
  
  }
  

  export const useGetFranchisee=(setFranchisee:(data:FranchiseeProps[])=>void,setFetching:(val:boolean)=>void)=>{
    useEffect(()=>{
      getFranchisee(setFranchisee,setFetching)
    },[])
  
  }



  export const useGetAppointments=(setAppointments:(data:Appointment[])=>void,setFetching:(val:boolean)=>void,franchisee_id:any)=>{
    useEffect(()=>{
      getAppointments(setAppointments,setFetching,franchisee_id)
    },[])
  
  }



  
  export const useGetheaders=(setheaders:(data:headerprops[])=>void,setFetching:(val:boolean)=>void)=>{
    useEffect(()=>{
      getheaders(setheaders,setFetching)
    },[])
  
  }

  export const useGetsubheaders=(setsubheaders:(data:subheaderprops[])=>void,setFetching:(val:boolean)=>void)=>{
    useEffect(()=>{
      getsubheaders(setsubheaders,setFetching)
    },[])
  
  }

  export const useGettransactions=(settransactions:(data:Transactionprops[])=>void,setFetching:(val:boolean)=>void,franchisee_id:any)=>{
    useEffect(()=>{
      gettransactions(settransactions,setFetching,franchisee_id)
    },[])
  
  }

  export const useGetheaderprofitloss=(setheaderprofitloss:(data:HeaderProfitLossprops[])=>void,setFetching:(val:boolean)=>void,selectedYear:any,selectedMonth:any,franchisee_id:any)=>{
    useEffect(()=>{
      getheaderprofitloss(setheaderprofitloss,setFetching,selectedYear,selectedMonth,franchisee_id)
    },[])
  
  }

  export const useGetprofitloss=(setprofitloss:(data:ProfitLossStatementprops[])=>void,setFetching:(val:boolean)=>void,selectedYear:any,selectedMonth:any,franchisee_id:any)=>{
    useEffect(()=>{
      getprofitloss(setprofitloss,setFetching,selectedYear,selectedMonth,franchisee_id)
    },[])
  
  }


  export const useGetSankey=(setSankey:(data:any[])=>void,setFetching:(val:boolean)=>void,month:any, year:any, franchisee:any)=>{
    useEffect(()=>{
      getSankey(setSankey,setFetching,month,year,franchisee)
    },[])
  
  }


  export const useGetsummarys=(setsummarys:(data:DashboardData)=>void,setFetching:(val:boolean)=>void, franchisee:any)=>{
    useEffect(()=>{
      getsummarys(setsummarys,setFetching,franchisee)
    },[])
  
  }

  export const useGetEmail=(setEmail:(data:EmailProps[])=>void,setFetching:(val:boolean)=>void)=>{

    useEffect(()=>{
      getEmail(setEmail,setFetching)
    },[])

}

export const useGetEmailTracking = (setEmailTrackings: (data: EmailTracking[]) => void, setFetching: (fetching: boolean) => void) => {
  useEffect(() => {
    getEmailTracking(setEmailTrackings, setFetching);
  }, [setEmailTrackings, setFetching]);
};



export const useGetsections=(setsections:(data:Sectionprop[])=>void,setFetching:(val:boolean)=>void)=>{
  useEffect(()=>{
    getSection(setsections,setFetching)
  },[])

}
export const useGetlessons=(setlessons:(data:Lessonprop[])=>void,setFetching:(val:boolean)=>void)=>{
  useEffect(()=>{
    getLesson(setlessons,setFetching)
  },[])

}

export const useGetlessoncontent=(setlessoncontent:(data:LessonContentprop[])=>void,setFetching:(val:boolean)=>void)=>{
  useEffect(()=>{
    getLessonContent(setlessoncontent,setFetching)
  },[])

}