import React, { useEffect, useState } from "react";
import { Card, Row, Col, Spin, notification, Collapse, Button, Modal, Input, Upload, Drawer } from "antd";
import { AuthTokenType, Lessonprop, Sectionprop } from "../utils/types";
import { useGetsections } from "../utils/hooks"; // Import your custom hook
import axios, { AxiosResponse } from "axios"; // Axios for API calls
import { getAuthToken, getSection } from "../utils/functions";
import { sectionsUrl, lessonsUrl, contentsUrl } from "../utils/network";
import { UploadOutlined } from "@ant-design/icons"; // For upload icon
import { DeleteOutlined, EditOutlined } from "@mui/icons-material";
import Compressor from "compressorjs";
import imageCompression from 'browser-image-compression';
const { Panel } = Collapse;
// Assuming you have a function to fetch userRole (from context or API)

// Main Component for Sections, Lessons, and Content
const Sections = () => {
  const [sections, setSections] = useState<Sectionprop[]>([]);  // State to store sections
  const [fetching, setFetching] = useState<boolean>(true);  // State to manage loading state
  const [showModal, setShowModal] = useState<boolean>(false);  // State to control modal visibility for section
  const [showLessonModal, setShowLessonModal] = useState<boolean>(false);  // State for adding lesson modal
  const [newSection, setNewSection] = useState<{ title: string; description: string }>({ title: "", description: "" });  // State for new section
  const [newLesson, setNewLesson] = useState<{ title: string; description: string; section_id: number }>({
    title: "",
    description: "",
    section_id: 0,  // Set to number type
  });  // State for new lesson
  const [videoFileList, setVideoFileList] = useState<any[]>([]); // For storing the video file list
  const [currentLessonId, setCurrentLessonId] = useState<number | null>(null); // Store current lesson ID when uploading video
  const [writeUpContent, setWriteUpContent] = useState<string>("");
  const [videoModalVisible, setVideoModalVisible] = useState(false);  // To control video modal visibility
  const [currentVideoUrl, setCurrentVideoUrl] = useState<string>(""); 
  const [uploadProgress, setUploadProgress] = useState<number>(0); 


  // Fetch user role when component mounts
  const userRole = localStorage.getItem('token1'); 

  const [drawerVisible, setDrawerVisible] = useState(false);  
  

  const [editingSection, setEditingSection] = useState<Sectionprop | null>(null);  // For editing section
  const [editingLesson, setEditingLesson] = useState<Lessonprop | null>(null);  // For editing lesson

  
  
  const handleShowDrawer = () => {
    setDrawerVisible(true);
  };

  const handleCloseDrawer = () => {
    if (uploadProgress < 100) {
      // If upload is still in progress, prevent closing the drawer
      notification.warning({ message: "Please wait for the upload to finish!" });
      return;
    }
    setDrawerVisible(false);
  };

// Utility function to get the file extension
const getFileExtension = (url: string) => {
  if (!url) return undefined;
  const fileName = url?.split('/').pop(); // Extract file name from the URL
  return fileName ? fileName.split('.').pop()?.toLowerCase() : undefined; // Get file extension
};

// Function to handle media click (for opening in modal or new tab)
const handleMediaClick = (url: string) => {
  if (!url) return; // Guard against undefined URL
  const extension = getFileExtension(url);
  
  if (['mp4', 'avi', 'mov', 'webm'].includes(extension || '')) {
    openVideoModal(url); // Open video in modal if it's a video
  } else {
    window.open(url, '_blank'); // Open other file types in a new tab
  }
};


  // Using the custom hook to fetch sections data
  useGetsections(setSections, setFetching);


  const openVideoModal = (videoUrl: string) => {
    setCurrentVideoUrl(videoUrl);  // Set the video URL to be played in the modal
    setVideoModalVisible(true);  // Open the video modal
  };

  const closeVideoModal = () => {
    setVideoModalVisible(false);  // Close the video modal
    setCurrentVideoUrl("");  // Reset the video URL
  };

  // If data is still fetching, show a loading spinner
  if (fetching) {
    return <Spin size="large" />;
  }

  const handleSectionInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setNewSection((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleLessonInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setNewLesson((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handle Delete Section

  const handleDeleteSection = async (sectionId: number) => {
    const headers = getAuthToken() as AuthTokenType;
  
    // Using Ant Design Modal.confirm for confirmation
    Modal.confirm({
      title: 'Are you sure you want to delete this section?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: async () => {
        try {
          await axios.delete(`${sectionsUrl}${sectionId}/`, headers );
          setSections((prevSections) => prevSections.filter((section) => section.id !== sectionId));
          notification.success({ message: "Section deleted successfully!" });
          getSection(setSections, setFetching);
        } catch (error) {
          notification.error({ message: "Failed to delete section!" });
        }
      },
      onCancel: () => {
        // If the user clicks "No", do nothing
      }
    });
  };
  
  const handleDeleteLesson = async (lessonId: number) => {
    const headers = getAuthToken() as AuthTokenType;
  
    // Using Ant Design Modal.confirm for confirmation
    Modal.confirm({
      title: 'Are you sure you want to delete this lesson?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: async () => {
        try {
          await axios.delete(`${lessonsUrl}${lessonId}/`, headers );
          setSections((prevSections) => prevSections.map((section) => ({
            ...section,
            lessons: section.lessons.filter((lesson) => lesson.id !== lessonId),
          })));
          getSection(setSections, setFetching);
          notification.success({ message: "Lesson deleted successfully!" });
        } catch (error) {
          notification.error({ message: "Failed to delete lesson!" });
        }
      },
      onCancel: () => {
        // If the user clicks "No", do nothing
      }
    });
  };
    // Handle form submission to create a new section
  // Handle Add/Edit Section
  const handleAddEditSection = async () => {
    const headers = getAuthToken() as AuthTokenType;

    if (!newSection.title.trim()) {
      notification.warning({ message: "Section title is required!" });
      return;
    }

    try {
      let response :AxiosResponse<any>;
      if (editingSection) {
        // Edit Section
        response = await axios.put(`${sectionsUrl}${editingSection.id}/`, newSection, headers );
        notification.success({ message: "Section edited successfully!" });
      } else {
        // Add Section
        response = await axios.post(sectionsUrl, newSection, headers );
        notification.success({ message: "Section added successfully!" });
      }

      setSections((prevSections) =>
        editingSection
          ? prevSections.map((section) =>
              section.id === editingSection.id ? { ...section, ...newSection } : section
            )
          : [...prevSections, response.data]
      );
      setShowModal(false);
      setNewSection({ title: "", description: "" });
      setEditingSection(null); // Clear editing state
      getSection(setSections, setFetching);
    } catch (error) {
      notification.error({ message: "Failed to add/edit section!" });
    }
  };

  // Handle Add/Edit Lesson
  const handleAddEditLesson = async () => {
    const headers = getAuthToken() as AuthTokenType;

    if (!newLesson.title.trim()) {
      notification.warning({ message: "Lesson title is required!" });
      return;
    }

    try { 
      let response:AxiosResponse<any>;
      if (editingLesson) {
        // Edit Lesson
        response = await axios.put(`${lessonsUrl}${editingLesson.id}/`, newLesson, headers );
        notification.success({ message: "Lesson edited successfully!" });
      } else {
        // Add Lesson
        response = await axios.post(lessonsUrl, newLesson, headers );
        notification.success({ message: "Lesson added successfully!" });
      }

      setSections((prevSections) =>
        prevSections.map((section) =>
          section.id === newLesson.section_id
            ? {
                ...section,
                lessons: editingLesson
                  ? section.lessons.map((lesson) =>
                      lesson.id === editingLesson.id ? { ...lesson, ...newLesson } : lesson
                    )
                  : [...section.lessons, response.data ],
              }
            : section
        )
      );
      setShowLessonModal(false);
      setNewLesson({ title: "", description: "", section_id: 0 });
      setEditingLesson(null); // Clear editing state
      getSection(setSections, setFetching);
    } catch (error) {
      notification.error({ message: "Failed to add/edit lesson!" });
    }
  };


  const handleVideoUpload = async (
    lessonId: number,
    videoFile: File | null,
    writeUp: string
  ) => {
    const headers = getAuthToken() as AuthTokenType;
    const formData = new FormData();
    
  
    // Append the lesson_id to form data
    formData.append("lesson_id", lessonId.toString());
  
    // Handle the file if provided (either video or image)
    if (videoFile) {
      const fileExtension = videoFile.name.split('.').pop()?.toLowerCase();
  
      if (['jpg', 'jpeg', 'png', 'gif', 'bmp'].includes(fileExtension!)) {
        // If it's an image, compress it before uploading
        try {
          const options = {
            maxSizeMB: 1, // Maximum size in MB
            maxWidthOrHeight: 640, // Maximum width or height
            useWebWorker: true, // Use web worker for compression
          };
  
          const compressedImage = await imageCompression(videoFile, options);
          formData.append("video", compressedImage);  // Send compressed image as "video"
        } catch (error) {
          notification.error({ message: "Failed to compress image!" });
          return;
        }
      } else if (['mp4', 'avi', 'mkv', 'mov'].includes(fileExtension!)) {
        // If it's a video, upload it directly
        formData.append("video", videoFile);
      } else {
        notification.error({ message: "Unsupported file type!" });
        return;
      }
    }
  
    // Append the write-up if provided
    if (writeUp.trim()) {
      formData.append("write_up", writeUp);
    }
  
    try {
      // Upload the video/image and other content to the server
      const response = await axios.post(contentsUrl, formData, {
        ...headers,
        onUploadProgress: (progressEvent) => {
          // Track progress and show a progress bar
          if (progressEvent.total) {
            const percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            // Update your progress state here, e.g. setUploadProgress(percent)
          }
        },
      });
  
      notification.success({ message: "File and content uploaded successfully!" });
      getSection(setSections, setFetching);
      setUploadProgress(100); 
    } catch (error) {
      notification.error({ message: "Failed to upload file and content!" });
    }
  };

  return (
    <div className="card">
              <div className="cardHeader">

          <h2 className="headContent">My Learning</h2>
          <div className="rightContent">
          {userRole === "Admin" && (
      <Button
        onClick={() => setShowModal(true)}
        style={{ marginBottom: "20px" }}
      >
        Add Section
      </Button>
        )}
      </div>
      </div>
      {/* Loop through each section and display them in cards */}
      {sections.map((section) => (
    <div className="card">
    <div className="cardHeader">

<h2 className="headContent">{section.title} </h2>
<div className="rightContent">
{/* Button to add new lesson */}
{userRole === "Admin" && (
          <Button
            type="dashed"
            onClick={() => {
              setShowLessonModal(true);
              setNewLesson((prev) => ({ ...prev, section_id: section.id }));  // Ensure correct type (number)
            }}
            style={{ marginBottom: "20px" }}
          >
            Add Lesson
          </Button>
          )}
{userRole === "Admin" && (

<Button
                icon={<EditOutlined />}
                style={{ marginBottom: "20px" }}

                onClick={() => {
                  setNewSection({ title: section.title, description: section.description || "" });
                  setEditingSection(section);
                  setShowModal(true);
                  
                }}
              >
                Edit Section
              </Button>
              )}
{userRole === "Admin" && (

<Button
                          style={{ marginBottom: "20px" }}

                icon={<DeleteOutlined />}
                onClick={() => handleDeleteSection(section.id)}
              >
                Delete Section
              </Button>
                )}
          </div>
          </div>
          {/* Section description */}
          <p>{section.description || "No description available"}</p>
        
          {/* Collapse component for lessons in each section */}
          <Collapse accordion>
          {section?.lessons && section.lessons.length > 0 ? (
    section.lessons.map((lesson, index) => (
<Panel
  header={
    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
    <span>{lesson?.title || `Lesson ${index + 1}`}</span>
    {userRole === "Admin" && (
    <Button.Group style={{ display: 'flex', gap: '8px' }}>
      <Button
        icon={<UploadOutlined />}
        onClick={(e) => {
          e.stopPropagation(); // Prevent the panel from opening
          setCurrentLessonId(lesson?.id);
          handleShowDrawer(); // Open the drawer for video upload
        }}
      >
      
      </Button>
      <Button
        icon={<DeleteOutlined />}
        onClick={() => handleDeleteLesson(lesson?.id)}
      >
        Delete Lesson
      </Button>
    </Button.Group>
     )}
  </div>
  }
  key={lesson?.id || `lesson-${index}`}
>
<div>

          {lesson?.contents && lesson.contents.length > 0 ? (
            <table style={{ width: "100%", marginTop: "16px", borderCollapse: "collapse" }}>
              <thead>
                <tr>
                  <th style={{ border: "1px solid #ddd", padding: "8px", textAlign: "center" }}>Details</th>
                  <th style={{ border: "1px solid #ddd", padding: "8px", textAlign: "center" }}>Content</th>
                        </tr>
              </thead>
              <tbody>
                {lesson.contents.map((content: any, contentIndex) => (
                  <tr key={content?.id || `content-${contentIndex}`}>
                    {/* Write-up Column */}
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                        <p>{content.write_up}</p>
                    </td>

                    {/* Video Column */}
                    <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>
  <div
    style={{
      cursor: 'pointer',
      position: 'relative',
      width: '100%',
      height: '120px',
      backgroundColor: '#ddd',
      backgroundImage: `url(${content.video})`, // Use content.fileUrl (or similar) here
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      borderRadius: '8px',
    }}
    onClick={() => handleMediaClick(content.video)} // Open media based on file type
  >
    {/* Handle Video */}
    {getFileExtension(content.video) === 'mp4' && (
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          fontSize: '24px',
          color: 'white',
          textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
        }}
      >
        ▶
      </div>
    )}

    {/* Handle Image */}
    {['jpg', 'jpeg', 'png', 'gif'].includes(getFileExtension(content.fileUrl) || '') && (
      <img
        src={content.video}
        alt="File Preview"
        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
      />
    )}

    {/* Handle PDF */}
    {getFileExtension(content.video) === 'pdf' && (
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          fontSize: '24px',
          color: 'white',
          textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
        }}
      >
        📄
      </div>
    )}

    {/* Handle Word Document */}
    {['doc', 'docx'].includes(getFileExtension(content.video) || '') && (
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          fontSize: '24px',
          color: 'white',
          textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
        }}
      >
        📝
      </div>
    )}
  </div>
</td>                   {/* Upload Button Column */}
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p style={{ marginTop: "16px" }}>No contents available for this lesson.</p>
          )}
        </div>
      </Panel>
    ))
  ) : (
    <Panel header="No Lessons Available" key="no-lessons">
      <p>No lessons available for this section.</p>
    </Panel>
  )}
          </Collapse>
        </div>
      ))}

      {/* Modal for adding a new section */}
      <Modal
        title="Add New Section"
        visible={showModal}
        onCancel={() => setShowModal(false)}
        onOk={handleAddEditSection}
        okText="Add Section"
      >
        <Input
          placeholder="Section Title"
          value={newSection.title}
          name="title"
          onChange={handleSectionInputChange}
          style={{ marginBottom: "10px" }}
        />
        <Input.TextArea
          placeholder="Section Description"
          value={newSection.description}
          name="description"
          onChange={handleSectionInputChange}
        />
      </Modal>
      <Drawer
  title={`Upload Content for Lesson ${currentLessonId}`}
  width={400}
  visible={drawerVisible}
  onClose={handleCloseDrawer}
  bodyStyle={{ padding: "20px", display: "flex", flexDirection: "column", height: "100%" }}
  footer={
    <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "auto" }}>
      <Button
        onClick={handleCloseDrawer}
        style={{ marginRight: "8px" }}
      >
        Cancel
      </Button>
      <Button
        type="primary"
        onClick={() => {
          // Trigger the video and write-up submission when the submit button is clicked
          if (videoFileList.length > 0 && currentLessonId !== null) {
            handleVideoUpload(currentLessonId, videoFileList[0].originFileObj, writeUpContent);
            // Close the drawer after submission
            handleCloseDrawer();
            // Clear the video file list after submission
            setVideoFileList([]);
          } else {
            notification.warning({ message: "Please upload a video before submitting!" });
          }
        }}
        disabled={!writeUpContent.trim() || videoFileList.length === 0} // Disable button if write-up is empty or no video is uploaded
      >
        Submit
      </Button>
      <div style={{ marginTop: '20px' }}>
        {uploadProgress > 0 && uploadProgress < 100 && (
          <div>
            <progress value={uploadProgress} max={100}></progress>
            <span>{uploadProgress}%</span>
          </div>
        )}
      </div>
    </div>
  }
>
  <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
    {/* Upload Button */}
    <Upload
  name="file"
  accept="*/*" // Accept all file types
  fileList={videoFileList}
  showUploadList={false}
  onChange={(info) => {
    // Filter out files larger than 100MB before updating the file list
    const filteredFileList = info.fileList.filter((file) => file.size && file.size <= 100 * 1024 * 1024);

    // If a file exceeds the size limit, show an error message
    if (info.fileList.length !== filteredFileList.length) {
      notification.error({
        message: "File size exceeds 100MB. Please upload a smaller file.",
      });
    }
    setUploadProgress(100); 
    // Update the file list with the filtered list (excluding large files)
    setVideoFileList(filteredFileList);
  }}
  beforeUpload={(file) => {
    // We don't need to check the file size here anymore, as we already handle it in onChange
    return true; // Proceed with the upload
  }}
>
  <Button
    icon={<UploadOutlined />}
    style={{ width: "100%", marginBottom: "20px" }} // Add marginBottom to the upload button
  >
    Upload Content
  </Button>
</Upload>

    {/* Video Write-up */}
    <Input.TextArea
      placeholder="Enter the lesson write-up here"
      value={writeUpContent}
      onChange={(e) => setWriteUpContent(e.target.value)} // Update the write-up content
      rows={4}
      style={{ marginBottom: "10px" }}
    />

    {/* Uploaded Files Section */}
    {videoFileList.length > 0 && (
      <div style={{ marginTop: "20px" }}>
<h4 style={{ marginLeft: "8px" }}>Uploaded Files:</h4> {/* Added marginBottom to the h4 */}        <div style={{ display: "flex", flexDirection: "column", gap: "10px", marginTop: "10px" }}>
          {videoFileList.map((file, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px",
                border: "1px solid #ddd",
                borderRadius: "8px",
                justifyContent: "space-between", // Align items in a row
              }}
            >
              {/* Video Thumbnail (optional) */}
              <div style={{ marginRight: "10px" }}>
                <video width="50" height="50" controls>
                  <source src={URL.createObjectURL(file.originFileObj)} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
              <div style={{ flex: 1 }}>
                <strong>{file.name}</strong> {/* Display file name */}
                <div style={{ fontSize: "12px", color: "#666" }}>
                  Size: {Math.round(file.size / 1024)} KB
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    )}
  </div>
</Drawer>
      {/* Modal for adding a new lesson */}
      <Modal
        title="Add New Lesson"
        visible={showLessonModal}
        onCancel={() => setShowLessonModal(false)}
        onOk={handleAddEditLesson}
        okText="Add Lesson"
      >
        <Input
          placeholder="Lesson Title"
          value={newLesson.title}
          name="title"
          onChange={handleLessonInputChange}
          style={{ marginBottom: "10px" }}
        />
        <Input.TextArea
          placeholder="Lesson Description"
          value={newLesson.description}
          name="description"
          onChange={handleLessonInputChange}
        />
      </Modal>
      <Modal
  title="Video Player"
  visible={videoModalVisible}
  onCancel={closeVideoModal}
  footer={null}
  width="60%" // Adjust width to make it smaller
  centered
  style={{ padding: "20px" }} // Optional: Add some padding to the modal
>
  <div style={{ display: "flex", justifyContent: "center" }}> {/* Center the video player */}
    <video
      controls
      style={{
        maxWidth: "100%", // Ensures it resizes to container
        height: "auto", // Maintains aspect ratio
        borderRadius: "8px", // Optional: Add rounded corners for a softer look
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Optional: Adds subtle shadow for a polished look
      }}
    >
      <source src={currentVideoUrl} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  </div>
</Modal> 
     </div>

      

  );
};

export default Sections;