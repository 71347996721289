import { BrowserRouter, Route, Routes  } from 'react-router-dom';
import Login from "./pages/Login"
import CheckUser from "./pages/CheckUser"
import Home from "./pages/Home"
import AuthRoute from './components/AuthRoute';
import User from './pages/User';
import UpdateUserPassword from './pages/UpdateUserPassword';
import Categories from './pages/Categories';
import Unitofmeasure from './pages/Unitofmeasure';
import Currencies from './pages/Currencies';
import Locations from './pages/Locations';
import Customers from './pages/Customers';
import Suppliers from './pages/Suppliers';
import Products from './pages/Products';
import PurchaseOrder from './pages/AddPurchase';
import PurchaseOrderReview from './pages/PurchaseOrderReview';
import SalesOrder from './pages/AddSales';
import SalesOrderReview from './pages/SalesOrderReview';
import StocktransferOrderReview from './pages/TransferOrderReview';
import StocktransferOrder from './pages/AddTransfers';
import Leads from './pages/Leads';
import Estimates from './pages/AddEstimate';
import EstimatesReview from './pages/EstimatesReview';
import SalesOrderPhotos from './pages/SalesOrderPhotos';
import OnHandInventory from './pages/OnHandInventory';
import PricebyLocation from './pages/PricebyLocation';
import TransferOrderfiles from './pages/TransferOrderFiles';
import PurchaseOrderfiles from './pages/PurchaseOrderFiles';
import ResetPassword from './pages/ResetPassword';
import ForgetPassword from './pages/ForgotPassword';
import Appointments from './pages/Appointments';
import AppointmentsCalendar from './pages/AppointmentsCalendar';
import Franchisee from './pages/Franchisee';
import Transactions from './pages/Transactions';
import ProfitLossComponent from './pages/Profitloss';
import Sankeychart from './components/Dashboard/Sankeychart';
import EstimatePhotos from './pages/Estimatephotos';
import { Email } from '@mui/icons-material';
import Emails from './pages/Emails';
import EmailTrackings from './pages/Emailtracking';
import CompletePage from './components/Completepage';
import Sections from './pages/University';


function Router() {
  return (
  <BrowserRouter>
      <Routes>
      
      <Route path="/Login"  element={<Login/>}/>
      <Route path="/check-user"  element={<CheckUser/>}/>
      <Route path="/create-password"  element={<UpdateUserPassword/>}/>
      <Route path="/forgotpassword" element={<ForgetPassword/>}/>
      <Route path="/resetpassword" element={<ResetPassword/>}/>


      <Route path="/" element={<AuthRoute><Home/></AuthRoute>}/>
      <Route path="/users" element={<AuthRoute><User/></AuthRoute>}/>

      <Route path="/category" element={<AuthRoute><Categories/></AuthRoute>}/>
      <Route path="/uom" element={<AuthRoute><Unitofmeasure/></AuthRoute>}/>
      <Route path="/currency" element={<AuthRoute><Currencies/></AuthRoute>}/>
      <Route path="/location" element={<AuthRoute><Locations/></AuthRoute>}/>
      <Route path="/customer" element={<AuthRoute><Customers/></AuthRoute>}/>
      <Route path="/supplier" element={<AuthRoute><Suppliers/></AuthRoute>}/>
      <Route path="/product" element={<AuthRoute><Products/></AuthRoute>}/>
      <Route path="/purchase" element={<AuthRoute><PurchaseOrder/></AuthRoute>}/>
      <Route path="/purchasereview" element={<AuthRoute><PurchaseOrderReview/></AuthRoute>}/>
      <Route path="/sales" element={<AuthRoute><SalesOrder/></AuthRoute>}/>
      <Route path="/salesreview" element={<AuthRoute><SalesOrderReview/></AuthRoute>}/>
      <Route path="/transfers" element={<AuthRoute><StocktransferOrder/></AuthRoute>}/>
      <Route path="/transfersreview" element={<AuthRoute><StocktransferOrderReview/></AuthRoute>}/>
      <Route path="/leads" element={<AuthRoute><Leads/></AuthRoute>}/>
      <Route path="/estimates" element={<AuthRoute><Estimates/></AuthRoute>}/>
      <Route path="/reviewestimates" element={<AuthRoute><EstimatesReview/></AuthRoute>}/>
      <Route path="/salesorderphotos" element={<AuthRoute><SalesOrderPhotos/></AuthRoute>}/>
      <Route path="/onhand" element={<AuthRoute><OnHandInventory/></AuthRoute>}/>
      <Route path="/pricebylocation" element={<AuthRoute><PricebyLocation/></AuthRoute>}/>
      <Route path="/transferfiles" element={<AuthRoute><TransferOrderfiles/></AuthRoute>}/>
      <Route path="/purchasefiles" element={<AuthRoute><PurchaseOrderfiles/></AuthRoute>}/>
   
      <Route path="/appointments" element={<AuthRoute><Appointments/></AuthRoute>}/>

      <Route path="/appoinmentscalendar" element={<AuthRoute><AppointmentsCalendar/></AuthRoute>}/>
      <Route path="/franchisee" element={<AuthRoute><Franchisee/></AuthRoute>}/>
      <Route path="/transactions" element={<AuthRoute><Transactions/></AuthRoute>}/>
      <Route path="/profitloss" element={<AuthRoute><ProfitLossComponent/></AuthRoute>}/>
      <Route path="/estimatephotos" element={<AuthRoute><EstimatePhotos/></AuthRoute>}/>

      <Route path="/emails" element={<AuthRoute><Emails/></AuthRoute>}/>
      <Route path="/emailtrack" element={<AuthRoute><EmailTrackings/></AuthRoute>}/>

      <Route path="/university" element={<AuthRoute><Sections/></AuthRoute>}/>





      </Routes>
  </BrowserRouter>
  )
}
export default Router